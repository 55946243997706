// eslint-disable-next-line import/no-anonymous-default-export
export default {
	'menu_nav.home': 'Trang chủ',
	'menu_nav.shop': 'Cửa hàng',
	'menu_nav.freePattern': 'Chart miễn phí',
	'menu_nav.blog': 'Blog',
	'menu_nav.about': 'Về chúng tôi',
	'menu_nav.contact': 'Liên hệ',
	'menu_nav.detail': 'Chi tiết',
	'menu_nav.create': 'Thêm mới',
	'menu_nav.menu_noExist': 'Không tồn tại',
	'tab.all': 'Tất cả',
	'home_product_title': 'Sản phẩm',
	'home_product_description': 'Đây là những sản phẩm mình bán nhé. Ngoài ra mình cũng có nhận làm theo yêu cầu!!',
	'home_freepattern_title': 'Chart miễn phí',
	'home_freepattern_description': 'Đây là những chart mình sưu tập, và một số chart mình viết. Nếu mình lỡ có đăng phải chart bản quyền thì nhắc mình xóa nhé!!',
	'home_blog_title': 'Blogs',
	'home_blog_description': 'Đây là những bài viết của mình chia sẻ về móc len, và cuộc sống.',
	'home_social_title':'Theo dõi mình trên mạng xã hội nhé!',
	'home_social_description':'Fanpage và những kênh bán hàng của mình ạ!',
	'btn_grid': 'Dạng lưới',
	'btn_list': 'Danh sách',
	'shop_title': 'Sản phẩm',
	'shop_description': 'Dưới đây là những sản phẩm mà mình đã làm nè! Nếu các bạn muốn mình làm theo yêu cầu của bạn thì hãy inbox cho mình nhé! Tương lai, mình sẽ cập nhật thêm những sản phẩm mới và có thêm chức năng đặt hàng để các bạn dễ đặt hàng hơn nè. Hãy ủng hộ cho mình nhé!',
	'free_pattern_title': 'Chart miễn phí',
	'free_pattern_description': 'Dưới đây là những mẫu móc mà mình đã sưu tầm được ( Mình sẽ dịch dần dần nhé). Trong thời gian sắp tới, mình sẽ tiếp tục cập nhật thêm nhiều mẫu móc len đẹp khác và những mẫu mà mình tự sáng tạo nữa đấy. Hãy tham khảo và lựa chọn cho mình một mẫu ưng ý nhé!',
	'blog_title':'Nơi chia sẻ những điều tốt đẹp!!',
	'blog_description':'Nơi chia sẻ những điều tốt đẹp!!',
	'btn_readmore': 'Xem thêm',
	'btn_buy': 'Mua hàng',
	'btn_view_detail': 'Chi tiết',
	'btn_send': 'Gửi',
	'about_us_title': 'Chào mừng bạn đến website của tôi!',
	'about_us_content':'Cảm ơn bạn đã ghé thăm website của mình nhé. Hy vọng bạn sẽ tìm thấy những điều tốt đẹp ở đây. Mình rất thích móc len và tạo ra những mẫu đồ đẹp và độc đáo. Mong bạn sẽ thích những sản phẩm mình làm ra. Trên trang web của mình có rất nhiều mẫu móc len đẹp mà mình đã sưu tầm và dịch, tương lai mình sẽ cập nhật thêm nhiều mẫu móc len đẹp khác nữa và những mấu mà tự mình sáng tạo ra nè, bạn hãy tham khảo và chọn cho mình một mẫu ưng ý nhé. Ngoài những mẫu móc len đẹp ra mình cũng có bán những sản phẩm mình đã móc sẵn. Đối với những sản phẩm mình đặt trước, bạn có thể yêu cầu mình móc theo mẫu bạn muốn bằng cách gửi yêu cầu qua email của mình hoặc qua tin nhắn trên trang facebook của mình. Mình sẽ cố gắng hết sức để mang đến cho bạn những sản phẩm tốt nhất. Cảm ơn bạn đã ủng hộ mình!',
	'contact_title': 'Liên lạc với tôi',
	'contact_content': 'Bạn đang gặp vấn đề với đặt hàng hoặc yêu cầu móc theo mẫu bạn muốn? Hãy liên lạc với mình qua email hoặc tin nhắn trên trang facebook của mình nhé. Mình sẽ cố gắng hết sức để giúp bạn!',
	'contact_form_title': 'Gửi email cho mình nhé',
	'contact_via': 'Hoặc liên lạc với mình qua',
	'placeholder_input_email':'Nhập email của bạn',
	'placeholder_input_name':'Nhập tên',
	'placeholder_input_title':'Nhập tiêu đề',
	'placeholder_input_content':'Nhập nội dung',
	'error_msg_incorrect_email': 'Định dạng email không chính xác!',
	'intro_author': 'Tác giả:',
	'free_pattern_detail.detail': 'Chi tiết',
	'free_pattern_detail.content': 'Chart đã dịch',
	'free_pattern_note': 'Nếu mình nhỡ đăng nhằm chart có bản quyền, xin hãy nói với mình để mình gỡ xuống ạ! Cảm ơn bạn!',
	'product_detail.detail': 'Hình ảnh sản phẩm',
	'product_note': 'Nếu bạn muốn đặt mua sản phẩm này theo yêu cầu của bạn, hãy liên hệ với mình nhé. Mình sẽ cố gắng hết sức để mang đến cho bạn những sản phẩm tốt nhất. Cảm ơn bạn!',
	'notification.success': 'Thành công!',
	'notification.error': 'Lỗi!',
	'contact_form.success': 'Bạn đã gửi thư thành công!',
	'contact_form.error': 'Thư vẫn chưa được gửi!',
	
	'translation_status.ALL': 'TẤT CẢ',
	'translation_status.NONE': 'CHƯA DỊCH',
	'translation_status.PENDING': 'ĐANG DỊCH',
	'translation_status.SUCCESS': 'ĐÃ DỊCH',
}
 