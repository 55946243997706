// eslint-disable-next-line import/no-anonymous-default-export
export default {
	'menu_nav.home': 'Home',
	'menu_nav.shop': 'Shop',
	'menu_nav.freePattern': 'Free pattern',
	'menu_nav.blog': 'Blog',
	'menu_nav.about': 'About us',
	'menu_nav.contact': 'Contact',
	'menu_nav.detail': 'Detail',
	'menu_nav.create': 'Create',
	'menu_nav.menu_noExist': 'No exist',
	'tab.all': 'All',
	'home_product_title': 'Products',
	'home_product_description': 'These are the products I sell. In addition, I also accept orders!!',
	'home_freepattern_title': 'Free patterns',
	'home_freepattern_description': 'These are the charts I collected, and some charts I wrote. If I accidentally post a copyright chart, please remind me to delete it!!',
	'home_blog_title': 'Blogs',
	'home_blog_description': 'These are my articles sharing about crochet and life.',
	'home_social_title':'Follow me on Social',
	'home_social_description':'Fanpage and my sales channels!',
	'btn_grid': 'Grid',
	'btn_list': 'List',
	'shop_title': 'Products',
	'shop_description': 'Below are the products I made! If you want me to follow your order, please inbox me! In the future, I will update new products and add an ordering function to make it easier for you to order.',
	'free_pattern_title':'Free patterns',
	'free_pattern_description':'Below are the crochet patterns that I have collected (I will translate gradually). In the near future, I will continue to update many other beautiful crochet patterns and patterns that I create myself. Please refer and choose for yourself a satisfactory model!',
	'blog_title':'A place to share good things!!',
	'blog_description':'A place to share good things!!',
	'btn_readmore': 'Read more',
	'btn_buy': 'Buy',
	'btn_view_detail': 'View detail',
	'btn_send': 'Send',
	'about_us_title': 'Well comte to my website!',
	'about_us_content':'Thank you for visiting my website. Hope you will find good things here. I love crocheting and creating beautiful and unique patterns. Hope you will like the products I make. On my website, there are many beautiful crochet patterns that I have collected. In the future, I will update many more beautiful crochet patterns and patterns that I have created myself. Please refer and choose a model that suits you. In addition to crochet patterns, I also sell pre-crocheted products. For pre-order products, you can also ask me to crochet according to the pattern you want by sending a request via email or via message on my Facebook page. I will try my best to bring you the best products. Thank you for supporting me!',
	'contact_title': 'Get in touch with me',
	'contact_content': 'Are you having problems placing an order or requesting crochet in the pattern you want? Please contact me via email or message on my facebook page. I will try my best to help you!',
	'contact_form_title': 'Send email for me',
	'contact_via': 'Or contact me via',
	'placeholder_input_email':'Please input your email!',
	'placeholder_input_name':'Please input your name!',
	'placeholder_input_title':'Please input title!',
	'placeholder_input_content':'Please input content!',
	'error_msg_incorrect_email': 'Email format is incorrect!',
	'intro_author': 'Author:',
	'free_pattern_detail.detail': 'Chart detail',
	'free_pattern_detail.content': 'Translated chart',
	'free_pattern_note': 'If I accidentally posted a copyrighted chart, please tell me so I can take it down! Thank you!',
	'product_detail.detail': 'Product images',
	'product_note': 'If you want to order this product according to your requirements, please contact me. I will try my best to bring you the best products. Thank you!',
	'notification.success': 'Successfully!',
	'notification.error': 'Error!',
	'contact_form.success': 'You have sent email successfully!',
	'contact_form.error': 'The mail has not been sent yet!!',

	'translation_status.ALL': 'ALL',
	'translation_status.NONE': 'NONE',
	'translation_status.PENDING': 'PENDING',
	'translation_status.SUCCESS': 'TRANSLATED',
};
