const Dashboard = () => {

	return (
		<div>
			Dashboard
		</div>
	)
}

export default Dashboard;
